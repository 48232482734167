import React, { useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Form from "../../form/Form";
import AlertMessage from "../account_login/alert/AlertMessage";

const ForgotCompany = ({ appUrl, isMobile }) => {
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSuccess, setSuccess] = useState(false);
  const emailRef = useRef();
  const [message, setMessage] = useState(null);
  const [isError, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("email", emailRef.current.value);

    let url = "/v2/account/send-forgot-subdomain";
    fetch(url, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token":
          document.getElementsByTagName("META")["csrf-token"].content,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          setMessage(data.success);
          setSuccess(true);
        } else {
          setError(true);
          setErrorMessage(data.error);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleOnChange = () => {
    isError && setError(false);
  };

  return (
    <>
      {isSuccess && !isMobile && (
        <Redirect
          push
          to={{
            pathname: "/v2/account/index",
            state: {
              message: message,
              type: "success",
            },
          }}
        />
      )}
      <Form onSubmit={(e) => handleSubmit(e)}>
        {!isMobile && (
          <Form.Section formClass="flex-container align-left align-center-left padding-left-2 padding-top-1 padding-bottom-0">
            <span onClick={() => history.goBack()} className="back-btn">
              <i className="fas fa-arrow-left"></i> Back
            </span>
          </Form.Section>
        )}
        <Form.Heading formClass="padding-top-1">
          <div className="padding-horizontal-2 form-heading">
            <h3 className="margin-bottom-1">I forgot my company Subdomain</h3>
            <h4 className="text-primary">
              Enter your email below and we'll send you your subdomain
            </h4>
          </div>
        </Form.Heading>
        <Form.Body>
          {isSuccess && isMobile && (
            <AlertMessage type="notice">{message}</AlertMessage>
          )}
          <div>
            <div className="flex-container align-left align-center-left">
              <label htmlFor="email">Email address</label>
            </div>
            <input
              className={"margin-bottom-0 " + (isError ? "error-input" : "")}
              type="text"
              name="email"
              placeholder="Enter your email address"
              required
              ref={emailRef}
              onChange={handleOnChange}
            />
            <span className={"error " + (isError ? "error-message" : "")}>
              {isError ? "" : "Email address not found."}
              {errorMessage}
            </span>
          </div>
        </Form.Body>
        <Form.Footer formClass="padding-bottom-2 padding-top-0">
          <button
            type="submit"
            value="submit"
            className="next-button submit button padding-horizontal-2 margin-bottom-3"
          >
            Submit
          </button>
          {!isMobile && (
            <p className="margin-top-1">
              Don't have an account yet?{" "}
              <a
                href={`${appUrl}/home/request-trials`}
                data-turbolinks="false"
                rel="noopener noreferrer nofollow"
                className="margin-bottom-1"
              >
                Request a free trial here
              </a>
            </p>
          )}
        </Form.Footer>
      </Form>
    </>
  );
};

export default ForgotCompany;

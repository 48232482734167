import React, { useEffect, useState } from 'react';
import Form from '../../form/Form';
import AlertMessage from './alert/AlertMessage';

const PASSWORD_VALIDATION = {
  character: false,
  numbers: false,
  symbols: false,
};

const hasNumber = (str) => {
  return /^.*[0-9].*$/.test(str);
};

const hasSymbol = (str) => {
  return /^.*([-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).*$/.test(str);
};

const isValid = (str) => {
  return /^[A-Za-z]*(?=.*[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|])(?=.*[0-9]).{8,}$/.test(
    str
  );
};

const ChangePassword = ({ authToken }) => {
  const [passwordCondition, setPasswordCondition] =
    useState(PASSWORD_VALIDATION);
  const [passwordType, setPasswordType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordType, setCurrentPasswordType] = useState(true);
  const [disableButton, setDisable] = useState(false);
  const [isError, setError] = useState(false);
  const [isErrorCurrent, setErrorCurrent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const [forceLogout, setForceLogout] = useState(true);

  //regex ^[A-Za-z]*(?=.*[!@#$&*])(?=.*[0-9]).{8,}$
  const validatePassword = () => {
    //validate if password is at least 8 characters
    /^.{8,}$/.test(newPassword)
      ? setPasswordCondition((prev) => ({
          ...prev,
          character: true,
        }))
      : setPasswordCondition((prev) => ({
          ...prev,
          character: false,
        }));

    //validate if password contains at least 1 number
    hasNumber(newPassword)
      ? setPasswordCondition((prev) => ({
          ...prev,
          numbers: true,
        }))
      : setPasswordCondition((prev) => ({
          ...prev,
          numbers: false,
        }));

    //validate if password contains at least 1 special character/symbol
    hasSymbol(newPassword)
      ? setPasswordCondition((prev) => ({
          ...prev,
          symbols: true,
        }))
      : setPasswordCondition((prev) => ({
          ...prev,
          symbols: false,
        }));

    //validate if whole password is valid to enable button
    isValid(newPassword) ? setDisable(false) : setDisable(true);
  };

  const toggleShowPassword = (e) => {
    if (e.currentTarget.id === 'show-new-pass') {
      setPasswordType(!passwordType);
    } else if (e.currentTarget.id === 'show-cpass') {
      setConfirmPasswordType(!confirmPasswordType);
    } else {
      setCurrentPasswordType(!currentPasswordType);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append('authenticity_token', authToken);
    form.append('user[password]', newPassword);
    form.append('user[password_confirmation]', newPasswordConfirmation);
    form.append('user[current_password]', currentPassword);
    form.append('user[force_logout]', forceLogout);

    let url = '/v2/users/change-password-sub';

    fetch(url, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token':
          document.getElementsByTagName('META')['csrf-token'].content,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 'success') {
          setAlertMessage(data.message);
          setSuccess(true);
          setTimeout(() => {
            window.location.href = '/v2/feed';
          }, 5000);
        } else {
          setSuccess(false);
          setAlertMessage('');
          if (data.message.includes('current password')) {
            setErrorCurrent(true);
          } else {
            setError(true);
          }
          setCurrentPassword('');
          setDisable(true);
          setErrorMessage(data.message);
          setNewPassword('');
          setNewPasswordConfirmation('');
          setPasswordType(true);
          setConfirmPasswordType(true);
          setCurrentPasswordType(true);
        }
        console.log(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    validatePassword();
  }, [newPassword]);

  const handleOnchange = (e) => {
    isError && setError(false);
    isErrorCurrent && setErrorCurrent(false);
    setNewPassword(e.target.value);
  };

  return (
    <Form formClass='margin-auto-form' onSubmit={(e) => handleSubmit(e)}>
      <Form.Heading formClass='padding-top-2 padding-bottom-0'>
        <div className='padding-horizontal-2 form-heading'>
          <h3 className='margin-bottom-0'>Reset your password</h3>
          <h5 className='margin-bottom-1'>
            Your new password must contain the following:
          </h5>
          <div className='flex-container'>
            <ul className='flex-container align-center-middle align-center align-middle flex-dir-column'>
              <li className='flex-container align-center-middle align-center align-middle'>
                <span
                  className={
                    'margin-right-1 flex-container align-middle-center ' +
                    (passwordCondition.character ? 'accept' : '')
                  }>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z' />
                  </svg>
                </span>
                8 characters (minimum)
              </li>
              <li className='flex-container align-center-middle align-center align-middle'>
                <span
                  className={
                    'margin-right-1 flex-container align-middle-center ' +
                    (passwordCondition.numbers ? 'accept' : '')
                  }>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z' />
                  </svg>
                </span>
                Numbers (at least 1)
              </li>
              <li className='flex-container align-center-middle align-center align-middle'>
                <span
                  className={
                    'margin-right-1 flex-container align-middle-center ' +
                    (passwordCondition.symbols ? 'accept' : '')
                  }>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z' />
                  </svg>
                </span>
                Symbols (at least 1)
              </li>
            </ul>
          </div>
        </div>
        {isSuccess && (
          <div className='hidden width-75 margin-auto alert-message padding-top-1 margin-bottom-0'>
            <AlertMessage ms={5000} type='success'>
              {alertMessage}
            </AlertMessage>
          </div>
        )}
      </Form.Heading>
      <Form.Body formClass='padding-bottom-1 padding-top-1'>
        <div>
          <div className='flex-container align-left align-center-left'>
            <label htmlFor='current-password'>Current Password</label>
          </div>
          <div
            className={
              'password-container ' + (isErrorCurrent ? 'error-input' : '')
            }>
            <input
              type={currentPasswordType ? 'password' : 'text'}
              id='current-password'
              name='current-password'
              className={'margin-0 ' + (isErrorCurrent ? 'error-input' : '')}
              placeholder='Enter your current password'
              autoComplete='off'
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            {!currentPasswordType ? (
              <i
                id='show-currentpass'
                onClick={(e) => toggleShowPassword(e)}
                className='far fa-eye'></i>
            ) : (
              <i
                id='show-currentpass'
                onClick={(e) => toggleShowPassword(e)}
                className='far fa-eye-slash'></i>
            )}
          </div>
          <span className={'error ' + (isErrorCurrent ? 'error-message' : '')}>
            {isErrorCurrent ? errorMessage : 'error'}
          </span>
        </div>
        <div>
          <div className='flex-container align-left align-center-left'>
            <label htmlFor='new-password'>New password</label>
          </div>
          <div
            className={'password-container ' + (isError ? 'error-input' : '')}>
            <input
              type={passwordType ? 'password' : 'text'}
              id='new-password'
              name='new-password'
              className={'margin-0 ' + (isError ? 'error-input' : '')}
              placeholder='Enter your new password'
              autoComplete='off'
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={newPassword}
              onChange={(e) => handleOnchange(e)}
            />
            {!passwordType ? (
              <i
                id='show-new-pass'
                onClick={(e) => toggleShowPassword(e)}
                className='far fa-eye'></i>
            ) : (
              <i
                id='show-new-pass'
                onClick={(e) => toggleShowPassword(e)}
                className='far fa-eye-slash'></i>
            )}
          </div>
          <span className={'error ' + (isError ? 'error-message' : '')}>
            {isError ? errorMessage : 'error'}
          </span>
        </div>
        <div>
          <div className='flex-container align-left align-center-left'>
            <label htmlFor='confirm-password'>Confirm Password</label>
          </div>
          <div
            className={'password-container ' + (isError ? 'error-input' : '')}>
            <input
              type={confirmPasswordType ? 'password' : 'text'}
              id='confirm-password'
              name='confirm-password'
              className={'margin-0 ' + (isError ? 'error-input' : '')}
              placeholder='Confirm your new password'
              autoComplete='off'
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={newPasswordConfirmation}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
            />
            {!confirmPasswordType ? (
              <i
                id='show-cpass'
                onClick={(e) => toggleShowPassword(e)}
                className='far fa-eye'></i>
            ) : (
              <i
                id='show-cpass'
                onClick={(e) => toggleShowPassword(e)}
                className='far fa-eye-slash'></i>
            )}

          </div>
          <div className="flex-container align-justify">
              <div className="flex-container align-middle-center">
                <input
                  type="checkbox"
                  name="logout-devices"
                  id="logout-devices"
                  defaultChecked={true}
                  onClick={(e) => forceLogout ? setForceLogout(false) : setForceLogout(true)}
                  value={forceLogout}
                  className='margin-0'/>
                <label htmlFor="logout-devices">Require all my devices to log in again with my new password</label>
              </div>
          </div>
          <span className={'error ' + (isError ? 'error-message' : '')}>
            {isError ? errorMessage : 'error'}
          </span>
        </div>
      </Form.Body>
      <Form.Footer formClass='padding-vertical-0 padding-bottom-2'>
        <button
          disabled={disableButton ? true : false}
          className='next-button submit button padding-horizontal-2'>
          Change my password
        </button>
      </Form.Footer>
    </Form>
  );
};

export default ChangePassword;

/*
	LoadingCircle Component

	Default loading element of the app

	Props:
		> message:
			- Type: String
			- Required: false
			- Message during the loading process
		> addStyle:
			- Type: String
			- Required: false
			- Custom style for the component
*/

import React from 'react';

function LoadingCircle({ message, addStyle }) {
	return (
		<div className="loader-dual-ring-cont"
				 style={addStyle}>
			<div className="react-loader">
				<div></div>
				{message && ( <span>{message}</span> )}
			</div>
		</div>
	)
}

export default LoadingCircle;

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../form/Form';
import FormLinks from '../subdomain/util/FormLinks';
import AlertMessage from '../account_login/alert/AlertMessage';

const SubdomainLogin = ({ appUrl, domain, authToken }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [subdomain, setSubdomain] = useState('');
  const { state } = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append('authenticity_token', authToken);
    form.append('subdomain', subdomain);

    let url = '/v2/account_user_collections/generate_account_key';
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.getElementsByTagName('META')['csrf-token'].content,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          // console.log('success subdomain', data);
          window.location.href = data.redirect_url;
        } else{
          if (data.status === 'error') setErrorMessage(data.message);
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };

  const handleOnchange = (e) => {
    setSubdomain(e.target.value);
    setErrorMessage('')
  };

  return (
    <Form formClass='margin-auto-form' onSubmit={(e) => handleSubmit(e)}>
      <Form.Heading formClass='padding-top-2'>
        <div className='padding-horizontal-2 form-heading'>
          <h3 className='margin-bottom-1'>
          Connect all your Squadzip accounts to easily access them without having to login
          </h3>
          <h4 className='text-primary margin-bottom-1'>
          Enter your company's subdomain and click Next to continue:
          </h4>
          <p className='margin-0'>Once the setup is done, you can easily switch between accounts.</p>
        </div>
        {state?.message && (
          <div className='hidden margin-auto width-75 alert-message'>
            <AlertMessage type={state.type}>{state?.message}</AlertMessage>
          </div>
        )}
      </Form.Heading>
      <Form.Body formClass='padding-bottom-0 padding-top-0'>
      <div className={`subdomain-input-container ${errorMessage ? 'error-subdomain' : ''}`}>
          <input
            className='subdomain-input'
            type='text'
            name='subdomain'
            id='subdomain'
            autoComplete='off'
            value={subdomain}
            onChange={(e) => handleOnchange(e)}
            placeholder='subdomain'
            required
          />
          <div className='squadzip-com'>
            <span>.{domain}</span>
          </div>
        </div>
        <span
          style={{textAlign: 'center'}}
          className={
            "error " +
            (errorMessage ? "error-message display " : "hide")
          }>
          {errorMessage}
        </span>
      </Form.Body>
      <Form.Footer formClass='padding-bottom-2'>
        <button
          // onClick={(e) => handleSubmit(e)}
          className='next-button submit button padding-horizontal-4 margin-bottom-1'>
          Next &#8250;
        </button>
        <a
          href='/v2/account/forgot-subdomain'
          data-turbolinks='false'
          rel='noopener noreferrer nofollow'
          className='link-hover margin-bottom-1'>
          Forgot company subdomain?
        </a>
      </Form.Footer>
    </Form>
  );
};

export default SubdomainLogin;

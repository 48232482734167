import React from 'react';

class ModalCloseButton extends React.PureComponent {
	closeModal(e) {
		$(e.target).closest(".reveal").foundation("close");
	}

	render() {
		return (
			<button className="close-button"
							type="button"
							onClick={(e) => this.closeModal(e)}>
	      <span aria-hidden="true">
					<i className="fas fa-times"></i>
				</span>
	    </button>
		)
	}
}

export default ModalCloseButton;

import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import Modal from '../../../shared/modal';
import Slider1 from 'images/login/facebook_tutorial/slide_1.png';
import Slider2 from 'images/login/facebook_tutorial/slide_2.png';
import Slider3 from 'images/login/facebook_tutorial/slide_3.png';
import Slider4 from 'images/login/facebook_tutorial/slide_4.png';

// const Slider1 = lazy(() =>
//   import('images/login/facebook_tutorial/slide_1.png')
// );
// const Slider2 = lazy(() =>
//   import('images/login/facebook_tutorial/slide_2.png')
// );
// const Slider3 = lazy(() =>
//   import('images/login/facebook_tutorial/slide_3.png')
// );
// const Slider4 = lazy(() =>
//   import('images/login/facebook_tutorial/slide_4.png')
// );

const FacebookSyncTutorial = () => {
  const [imgLoad, setImgLoad] = useState(false);
  const [bulletIndex, setBulletIndex] = useState(0);
  const [skip, setSkip] = useState(false);
  const sliderData = [
    {
      description:
        'Step 1: <b>Log in to Squadzip</b> using the email you registered on Squadzip.',
      img: Slider1,
    },
    {
      description:
        'Step 2: Access <b>Settings</b> in the Me dropdown on the Top Bar.',
      img: Slider2,
    },
    {
      description: 'Step 3: Click <b>Sign in</b>, then log in to Facebook.',
      img: Slider3,
    },
    {
      description:
        'Step 4: Click <b>Close</b>. Now you can login with your Facebook Account!',
      img: Slider4,
    },
  ];
  const bulletRef = useRef(null);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (touchStart - touchEnd > 100 && touchEnd != 0) {
      nextSlide(e);
      setTouchEnd(0);
    }

    if (touchStart - touchEnd < -100 && touchEnd != 0) {
      prevSlide(e);
      setTouchEnd(0);
    }
  };

  const nextSlide = (e) => {
    e.preventDefault();
    var activeIdx = $('.orbit .orbit-bullets .is-active').data('slide');
    var changeIdx = $('li.orbit-slide.is-active').index() + 1;
    var isLTR = changeIdx > activeIdx ? true : false;
    if (activeIdx === changeIdx) return;
    var chosenSlide = $('.orbit .orbit-slide').filter(function (index) {
      return index === changeIdx;
    });
    $('.orbit').foundation('changeSlide', isLTR, chosenSlide, changeIdx);
    let arr = Array.from(bulletRef.current?.children ?? []);
    let currIndex = arr
      .filter((e) => {
        if (e.classList == 'is-active') return e;
      })
      .at(0).attributes['data-slide'].value;
    setBulletIndex((prev) => (prev = currIndex));
  };

  const prevSlide = (e) => {
    e.preventDefault();
    var activeIdx = $('.orbit .orbit-bullets .is-active').data('slide');
    var changeIdx = $('li.orbit-slide.is-active').index() - 1;
    var isLTR = changeIdx > activeIdx ? true : false;
    if (activeIdx === changeIdx) return;
    var chosenSlide = $('.orbit .orbit-slide').filter(function (index) {
      return index === changeIdx;
    });
    $('.orbit').foundation('changeSlide', isLTR, chosenSlide, changeIdx);
    let arr = Array.from(bulletRef.current?.children ?? []);
    let currIndex = arr
      .filter((e) => {
        if (e.classList == 'is-active') return e;
      })
      .at(0).attributes['data-slide'].value;
    setBulletIndex((prev) => (prev = currIndex));
  };

  const handleBulletChange = (e) => {
    e.preventDefault();
    let currBullet = e.target.attributes['data-slide'].value;
    if (currBullet == sliderData.length - 1)
      setBulletIndex(sliderData.length - 1);
    if (currBullet == 0) setBulletIndex(0);

    if (currBullet != 0 && currBullet != sliderData.length - 1)
      setBulletIndex(currBullet);
  };

  useEffect(() => {
    $('#modalFull').foundation('open');
  }, []);

  const screenResizing = () => {
    if (window.innerWidth == 720) window.innerWidth = 720;
  };

  useEffect(() => {
    screenResizing();

    window.addEventListener('resize', screenResizing);

    return () => {
      window.removeEventListener('resize', screenResizing);
    };
  });

  return (
    <Modal addId='need-help-modal' title=''>
      <div className='facebook-tutorial'>
        <div
          className='orbit'
          id='element-facebook-tutorial'
          role='region'
          aria-label='Squadzip Version 2'
          data-orbit
          data-auto-play='false'
          data-swipe='false'
          data-nav-buttons='true'>
          <div
            className='orbit-wrapper'
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}>
            <div className='orbit-controls-desktop'>
              {/* <button className='back-tour button'>
                <i className='fa fa-angle-left fa-3x'></i>
              </button> */}
              <button
                className={
                  'orbit-previous-btn button radius ' +
                  (bulletIndex == 0 ? 'visible-none' : 'visible-show')
                }
                onClick={prevSlide}>
                <i className='fa fa-angle-left fa-3x'></i>
              </button>
              <button
                className={
                  'orbit-next-btn button radius ' +
                  (bulletIndex == sliderData.length - 1
                    ? 'visible-none'
                    : 'visible-show')
                }
                onClick={nextSlide}>
                <i className='fa fa-angle-right fa-3x'></i>
              </button>
            </div>
            <ul className='orbit-container text-center'>
              <div className='skip-confirmation' style={{ display: 'none' }}>
                <div className='header-image'>
                  <div className='orbit-figure-header'>
                    <img
                      className='orbit-image flex-row-item'
                      src='#'
                      alt='Squadzip'
                      loading='lazy'
                      width={300}
                      height={400}
                    />
                  </div>
                </div>
              </div>
              <div className='quick-tour'>
                {Object.entries(sliderData).map(([key, value], index) => (
                  <li className='orbit-slide' key={key}>
                    <div className='padding-top-2 padding-left-2'>
                      <h4 className='text-left'>
                        How to connect your Facebook to Squadzip:
                      </h4>
                    </div>
                    <div className='header-image'>
                      <img
                        className='orbit-image'
                        src={value.img}
                        alt='Squadzip'
                        loading='lazy'
                        width={300}
                        height={200}
                      />
                    </div>
                    <div className='desc-cont card-section'>
                      <h5
                        dangerouslySetInnerHTML={{ __html: value.description }}
                      />
                    </div>
                  </li>
                ))}
              </div>
            </ul>
            <div className='modal-footer'>
              <nav className='orbit-bullets'>
                <div className='orbit-controls-mobile'>
                  <a
                    className={
                      'button primary clear btn-next ' +
                      (bulletIndex == 0 ? 'visible-none' : 'visible-show')
                    }
                    onClick={prevSlide}
                    style={{ cursor: 'pointer' }}>
                    <i className='fa fa-angle-left fa-1x'></i> Back
                  </a>
                </div>
                <div className='bullets' ref={bulletRef}>
                  {Object.entries(sliderData).map(([key, value], index) => (
                    <button
                      className={index == 0 ? 'is-active' : ''}
                      onClick={handleBulletChange}
                      key={key}
                      data-slide={index}
                      id={`bullet-${index}`}
                    />
                  ))}
                </div>
                <div className='orbit-controls-mobile'>
                  <a
                    className={
                      'button primary clear btn-next ' +
                      (bulletIndex == sliderData.length - 1
                        ? 'visible-none'
                        : 'visible-show')
                    }
                    onClick={nextSlide}
                    style={{ cursor: 'pointer' }}>
                    Next <i className='fa fa-angle-right fa-1x'></i>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </Modal>
  );
};

export default FacebookSyncTutorial;

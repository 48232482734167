import React, { lazy, Suspense } from 'react';
import ReactDom from 'react-dom';
import LoadingCircle from '../shared/LoadingCircle';
import FacebookSyncTutorial from './pages/social_media/FacebookSyncTutorial';

const FbLandingPage = lazy(() =>
  import('./pages/social_media/FacebookLandingPage')
);

const LoadingScreen = () => {
  return (
    <div className='sz-lazy-loading-screen'>
      <LoadingCircle />
    </div>
  );
};

function showSyncingTutorial(event) {
  // console.log(document.getElementById('modalSmall'));

  ReactDom.render(
    <FacebookSyncTutorial />,
    document.getElementById('modalFull')
  );
}

const SocialMediaRedirection = ({ payload }) => {
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <section>
          <FbLandingPage
            payload={payload}
            showSyncingTutorial={(e) => showSyncingTutorial(e)}
          />
        </section>
      </Suspense>
    </>
  );
};

export default SocialMediaRedirection;

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Form from '../../form/Form';
import FormLinks from './util/FormLinks';
import AlertMessage from '../account_login/alert/AlertMessage';

const Subdomain = ({ appUrl, domain }) => {
  const [subdomain, setSubdomain] = useState('');
  const { state } = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append('subdomain', subdomain);
    let url = '/v2/account/sign-in-subdomain';
    fetch(url, {
      method: 'put',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token':
          document.getElementsByTagName('META')['csrf-token'].content,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (Object.keys(data).length !== 0) {
          // console.log('success subdomain', data);
          window.location.href = data.url;
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };

  const handleOnchange = (e) => {
    setSubdomain(e.target.value);
  };

  return (
    <Form formClass='margin-auto-form' onSubmit={(e) => handleSubmit(e)}>
      <Form.Heading formClass='padding-top-2'>
        <div className='padding-horizontal-2 form-heading'>
          <h3 className='margin-bottom-1'>
            Squadzip makes staying on top of your business as easy as using
            social media
          </h3>
          <h4 className='text-primary margin-bottom-1'>
            Enter your company's subdomain and click <strong>Next</strong> to
            continue:
          </h4>
        </div>
        {state?.message && (
          <div className='hidden margin-auto width-75 alert-message'>
            <AlertMessage type={state.type}>{state?.message}</AlertMessage>
          </div>
        )}
      </Form.Heading>
      <Form.Body formClass='padding-bottom-0 padding-top-0'>
        <div className='subdomain-input-container'>
          <input
            className='subdomain-input'
            type='text'
            name='subdomain'
            id='subdomain'
            autoComplete='off'
            value={subdomain}
            onChange={(e) => handleOnchange(e)}
            placeholder='subdomain'
            required
          />
          <div className='squadzip-com'>
            <span>.{domain}</span>
          </div>
        </div>
      </Form.Body>
      <Form.Footer formClass='padding-bottom-2'>
        <button
          // onClick={(e) => handleSubmit(e)}
          className='next-button submit button padding-horizontal-4 margin-bottom-1'>
          Next &#8250;
        </button>
        <FormLinks appUrl={appUrl} />
      </Form.Footer>
    </Form>
  );
};

export default Subdomain;

import React from 'react';
import PropTypes from 'prop-types';
import ModalTitle from './ModalTitle';
import ModalCloseButton from './ModalCloseButton';

/**
  Modal Component

  Component for the standard design of the Modal

  Props:
    - children:
      > Type: Element/Component
      > Required: true
      > The inner html element of the modal
    - title
      > Type: String
      > Required: true
      > Title of the modal
    - titleIcon
      > Type: Element/Component
      > Required: false
      > Custom Icon before the title
    - addClass
      > Type: String
      > Required: false
      > Custom class that can add to the wrapper of the modal
    - addId
      > Type: String
      > Required: false
      > Custom id that can add to the wrapper of the modal
    - noClose
      > Type: Boolean
      > Required: false
      > It can remove the close button of the modal
    - contentClass
      > Type: String
      > Required: false
      > Custom class for the content
    - contentStyle
      > Type: String
      > Required: false
      > Custom style for the content
*/
class Modal extends React.PureComponent {
  /**
   * Declare here the initial state of the modal component.
   * If modal was being called on different pages, add the id of the main container
   * in the @property {array} idContainer
   */
  constructor() {
    super();

    this.state = {
      windowScrollPosition: 0,
    };

    this.idContainerArr = [
      '#feed-content-container',
      '#manage-template-container',
      '#feed-content-container-expense',
      '#my-export-container',
    ];
  }

  /**
   * On mount, set the current position of the main container to
   * avoid the sudden scrolling up.
   */
  componentDidMount() {
    let itemContainer, windowScrollYPosition;

    $(
      '#modalLarge, #modalSmall, #modalTiny, #modalMarissa, #modalFull, #wikiModal'
    ).foundation();

    windowScrollYPosition = window.scrollY;
    itemContainer = document.querySelector(this.idContainerArr);

    if (itemContainer) {
      itemContainer.style.top = `-${windowScrollYPosition}px`;
      itemContainer.style.position = 'relative';
      this.setState({ windowScrollPosition: windowScrollYPosition });
    }
  }

  /**
   * Once the modal is closed, remove the property of top and position
   * of the main container.
   */
  componentWillUnmount() {
    let itemContainer, windowScrollYPosition;

    windowScrollYPosition = window.scrollY;
    itemContainer = document.querySelector(this.idContainerArr);

    if (itemContainer) {
      itemContainer.style.removeProperty('top');
      itemContainer.style.removeProperty('position');
      window.scrollTo(0, this.state.windowScrollPosition);
    }
  }

  /**
   * Modal main component
   * @return {Component}
   */
  render() {
    const {
      children,
      title,
      titleIcon,
      ziptagName,
      addClass,
      noClose,
      contentClass,
      contentStyle,
      addId,
      iconClick,
      titleClass,
    } = this.props;

    return (
      <div className={addClass} id={addId}>
        {noClose ? null : <ModalCloseButton />}
        <ModalTitle
          title={title}
          titleIcon={titleIcon}
          ziptagName={ziptagName}
          iconClick={iconClick}
          titleClass={titleClass}
        />
        <div
          className={`sz-modal-cont clearfix ${contentClass || ''}`}
          style={contentStyle || {}}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  addClass: PropTypes.string,
  addId: PropTypes.string,
  contentClass: PropTypes.string,
  contentStyle: PropTypes.object,
  noClose: PropTypes.bool,
  titleIcon: PropTypes.string,
  ziptagName: PropTypes.string,
};

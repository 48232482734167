import React from 'react';

export default class ModalTitle extends React.PureComponent {
  // ADD Actions by adding react children to this component
  // as much as possible, use icons for actions

  render() {
    return (
      <div className="modal-title">
        {this.props.titleIcon && this.props.ziptagName ? (
          <div>
            {this.props.title}{' '}
            <a onClick={this.props.iconClick}>
              <i className={'fas ' + this.props.titleIcon}></i>
            </a>{' '}
            {this.props.ziptagName}
          </div>
        ) : (
          <div className={this.props.titleClass ? this.props.titleClass : ''}>
            {this.props.titleIcon ? (
              <a onClick={this.props.iconClick}>
                <i className={'fas ' + this.props.titleIcon}></i>
              </a>
            ) : null}{' '}
            {this.props.title}
          </div>
        )}
        <div className="modal-actions">
          {this.props.children
            ? React.Children.map(this.props.children, function (child) {
                return React.cloneElement(child);
              })
            : null}
        </div>
      </div>
    );
  }
}

import React from 'react';
import { Link } from 'react-router-dom';

const LINKS = [
  { url: '/v2/account/forgot-subdomain', title: 'Forgot company subdomain?' },
  { url: '/home/request-trials', title: 'Request a free trial' },
  { url: '/home/contact-us-v2', title: 'Contact us' },
];

const FormLinks = ({ buttonText, appUrl }) => {
  return (
    <>
      {LINKS.map((link) => (
        <a
          key={link.url}
          href={appUrl + link.url}
          data-turbolinks='false'
          rel='noopener noreferrer nofollow'
          className='link-hover margin-bottom-1'>
          {link.title}
        </a>
      ))}
    </>
  );
};

export default FormLinks;

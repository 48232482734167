import React from 'react';

const Footer = ({ appUrl }) => {
  return (
    <footer id='footer-login'>
      <div className='padding-3 footer-details'>
        <div className='width-100 container'>
          <main className='flex-child-shrink'>
            <div className='flex-container flex-dir-row about-address'>
              <div className='about-container flex-child-shrink'>
                <h2 className='margin-bottom-1'>About Us</h2>
                <p className='text-justify'>
                  Squadzip is the Digital Extension of the Office, built to help
                  manage sales and operations teams.
                </p>
                <p className='text-justify'>
                  Squadzip is an investee of{' '}
                  <a
                    href='https://kickstart.ph/companies'
                    rel='noopener noreferrer nofollow'
                    target='_blank'>
                    <strong>Kickstart Ventures</strong>
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://www.plugandplayapac.com/'
                    rel='noopener noreferrer nofollow'
                    target='_blank'>
                    <strong>Plug and Play Tech Center</strong>
                  </a> and a member of the{' '}
                  <a
                    href='https://aws.amazon.com/partners/'
                    rel='noopener noreferrer nofollow'
                    target='_blank'>
                    <strong>Amazon Partner Network (APN)</strong>
                  </a>
                  .
                </p>
              </div>
              <div className='address-container flex-child-shrink'>
                <h2 className='margin-bottom-1'>Address</h2>
                <div className='flex-container flex-dir-row'>
                  <div className='padding-right-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      className='bi bi-geo-alt-fill map-icon'
                      viewBox='0 0 16 16'>
                      <path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z' />
                    </svg>
                  </div>
                  <div>
                    <p className='sz-address'>
                      5F Microlab Building,
                      <br />
                      1157 Rodriguez Avenue,
                      <br />
                      Bangkal, Makati City, Philippines
                    </p>
                  </div>
                </div>
                <div>
                  <div className='col-12'>
                    <p className='sz-credits'>
                      {' '}
                      Credits
                      <br />
                      <a
                        href='https://www.openstreetmap.org/copyright'
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                        className='text-white fw-bold'>
                        © OpenStreetMap contributors
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className='gradient-border'></div>
      <div id='footer'>
        <div className='width-100 container'>
          <main className='flex-child-shrink'>
            <div className='flex-container flex-dir-row align-justify copyright-container'>
              <div className='flex-container align-center align-middle align-center-middle copyright-container-wrapper'>
                <div className='flex-container flex-dir-row copyright-container-left'>
                  <div className='width-100 flex-container align-justify copyright-links-container'>
                    <div className='footer-copyright'>
                      <p className='padding-bottom-1'>
                        © Copyright 2022 by Digital Space Explorer, Inc. All
                        Rights Reserved.
                      </p>
                    </div>
                    <div className='margin-horizontal-8 margin-auto footer-links'>
                      <p className='padding-bottom-1'>
                        <a
                          href={`${appUrl}/home/privacy-policy-v2`}
                          rel='noopener nofollow noreferrer'
                          data-turbolinks='false'>
                          Privacy Policy
                        </a>
                        <a
                          href={`${appUrl}/home/terms-and-conditions-v2`}
                          rel='noopener nofollow noreferrer'
                          data-turbolinks='false'>
                          Terms of Service
                        </a>
                        <a
                          href={`${appUrl}/home/contact-us-v2`}
                          rel='noopener nofollow noreferrer'
                          data-turbolinks='false'>
                          Contact Us
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className='flex-container align-center align-middle align-center-middle squadzip-socials'>
                      <a
                        href='https://www.linkedin.com/company/squadzip/'
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                        className='flex-container align-center align-middle align-center-middle'>
                        <svg
                          width='40'
                          height='40'
                          viewBox='0 0 40 40'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20ZM10.25 16.625V30H14.5V16.625H10.25ZM10 12.375C10 13.75 11 14.75 12.375 14.75C13.75 14.75 14.75 13.75 14.75 12.375C14.75 11 13.75 10 12.375 10C11.125 10 10 11 10 12.375ZM25.75 30H29.75V21.75C29.75 17.625 27.25 16.25 24.875 16.25C22.75 16.25 21.25 17.625 20.875 18.5V16.625H16.875V30H21.125V22.875C21.125 21 22.375 20 23.625 20C24.875 20 25.75 20.625 25.75 22.75V30Z'
                            fill='white'
                          />
                        </svg>
                      </a>
                      <a
                        href='https://www.facebook.com/squadzip'
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                        className='flex-container align-center align-middle align-center-middle'>
                        <svg
                          width='40'
                          height='40'
                          viewBox='0 0 40 40'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20ZM20 10C25.5 10 30 14.5 30 20C30 25 26.375 29.25 21.375 30V22.875H23.75L24.25 20H21.5V18.125C21.5 17.375 21.875 16.625 23.125 16.625H24.375V14.125C24.375 14.125 23.25 13.875 22.125 13.875C19.875 13.875 18.375 15.25 18.375 17.75V20H15.875V22.875H18.375V29.875C13.625 29.125 10 25 10 20C10 14.5 14.5 10 20 10Z'
                            fill='white'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

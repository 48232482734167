import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Form from "../../form/Form";
import countryCode from "../../phone.json";
import AlertMessage from "../account_login/alert/AlertMessage";

const ERROR_STATUS = {
  fullName: false,
  mobileNumber: false,
};

const ERROR_MESSAGE = {
  fullName: null,
  mobileNumber: null,
};

const ForgotEmail = ({ isMobile, appUrl }) => {
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState(ERROR_MESSAGE);
  const [isSuccessMobile, setSuccessMobile] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [alertType, setAlertType] = useState("");
  const fullNameRef = useRef();
  const [mobileNumber, setMobileNumber] = useState("");
  const [isError, setError] = useState(ERROR_STATUS);
  const [message, setMessage] = useState(null);
  const [code, setCode] = useState("63");
  const [index, setIndex] = useState(170);
  const [isDropdownOpen, setDropdown] = useState(false);
  const codeRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    let number = 0;
    if (mobileNumber.charAt(0) == 0 && code == "63") {
      number = code + mobileNumber.substring(1, mobileNumber.length);
    } else {
      number = code + mobileNumber;
    }

    let form = new FormData();
    form.append("full_name", fullNameRef.current.value);
    // form.append('mobile_no', mobileNumberRef.current.value);
    form.append("mobile_no", number);
    let url = "/v2/account/send-forgot-email";
    fetch(url, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token":
          document.getElementsByTagName("META")["csrf-token"].content,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          setMessage(data.success);
          setSuccess(true);

          setAlertType("success");
          setMobileNumber("");
          fullNameRef.current.value = "";
          if (isMobile) {
            setSuccessMobile(true);
            setTimeout(() => {
              // After 5 seconds set the show value to false
              setSuccessMobile(false);
            }, 10000);
          }
        } else {
          if (data.error.includes("mobile number")) {
            setError((prev) => ({ ...prev, mobileNumber: true }));
            setErrorMessage((prev) => ({ ...prev, mobileNumber: data.error }));
            if (data.error.includes("full name")) {
              setError((prev) => ({ ...prev, fullName: true }));
              setErrorMessage((prev) => ({ ...prev, fullName: data.error }));
            }
          } else {
            setError((prev) => ({
              ...prev,
              fullName: true,
              mobileNumber: true,
            }));
            setErrorMessage({ fullName: data.error, mobileNumber: data.error });
          }
          setAlertType("error");
        }
      })
      .catch((error) => console.log(error));
  };

  const handleOnChange = (e) => {
    // isError && setError({ fullName: false, mobileNumber: false });
    if (e.target.name === "full-name" && isError.fullName) {
      setErrorMessage((prev) => ({ ...prev, fullName: null }));
      setError((prev) => ({ ...prev, fullName: false }));
    }
    if (e.target.name === "mobile-number" && isError.mobileNumber) {
      setErrorMessage((prev) => ({ ...prev, mobileNumber: null }));
      setError((prev) => ({ ...prev, mobileNumber: false }));
    }
    if (e.target.name === "mobile-number")
      setMobileNumber(
        e.target.value.replace(/[^0-9]/g, "").replace(/(\+.*?)\+.*/g, "$1")
      );
  };

  const handleDropdown = () => {
    setDropdown(!isDropdownOpen);
  };

  const handleBackButton = (e) => {
    e.preventDefault();

    if (isMobile) {
      let androidJSIBridge, iosJSIBridge;
      androidJSIBridge = new JSIBridge(new AndroidBridge());
      iosJSIBridge = new JSIBridge(new IosBridge());
      androidJSIBridge.send(SquadzipSenderCode.EXIT_BUTTON, {});
      iosJSIBridge.send(SquadzipSenderCode.EXIT_BUTTON, {});
    } else {
      history.replace({ pathname: "/v2/users/sign_in", state: {} });
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      codeRef.current.children[index].scrollIntoView({
        block: "nearest",
        inline: "start",
      });
    }
    return () => {};
  }, [isDropdownOpen]);

  return (
    <>
      {isSuccess && !isMobile && (
        <Redirect
          push
          to={{
            pathname: "/v2/users/sign_in",
            state: {
              message: message,
              type: "success",
            },
          }}
        />
      )}
      <Form onSubmit={(e) => handleSubmit(e)} isMobile={isMobile}>
        {!isMobile && (
          <Form.Section formClass="flex-container align-left align-center-left padding-left-2 padding-top-1 padding-bottom-0">
            <span onClick={handleBackButton} className="back-btn">
              <i className="fas fa-arrow-left"></i> Back
            </span>
          </Form.Section>
        )}
        <Form.Heading formClass="padding-top-1 padding-bottom-0">
          <div className="padding-horizontal-2 form-heading padding-bottom-1">
            <h3 className="margin-bottom-1">Forgot your email address?</h3>
            <h4 className="text-primary padding-bottom-1">
              Don't worry, we'll contact you to let you know your email address
              used in Squadzip. Let us first verify who you are.
            </h4>
          </div>
          {isSuccessMobile && (
            <div className="hidden width-75 margin-auto alert-message">
              <AlertMessage type={alertType}>{message}</AlertMessage>
            </div>
          )}
        </Form.Heading>
        <Form.Body formClass="padding-bottom-1">
          <div>
            <div className="flex-container align-left align-center-left">
              <label htmlFor="full-name" className="text-left">
                Your full name on Squadzip{" "}
                <span className="disclaimer text-muted">
                  ( e.g. Juan Dela Cruz)
                </span>
              </label>
            </div>
            <input
              className={
                "margin-bottom-0 " + (isError.fullName ? "error-input" : "")
              }
              type="text"
              name="full-name"
              placeholder="Enter your full name"
              required
              ref={fullNameRef}
              onChange={(e) => handleOnChange(e)}
            />
            <span
              className={
                "error padding-bottom-1 " +
                (isError.fullName ? "error-message" : "")
              }
            >
              {errorMessage.fullName}
            </span>
          </div>
          <div>
            <div className="flex-container align-left align-center-left">
              <label htmlFor="mobile-number" className="text-left">
                Mobile number{" "}
                <span className="disclaimer text-muted">
                  (e.g. +639XXXXXXXXX)
                </span>
              </label>
            </div>
            <div className="mobile-number-container">
              <div className="flag-dropdown" onClick={handleDropdown}>
                <div className="selected-flag">
                  <span className="margin-auto selected-flag-code">
                    +{code}
                  </span>
                  <span className="margin-auto arrow-down">&#x25BC;</span>
                </div>
                {isDropdownOpen && (
                  <ul
                    className="country-list"
                    ref={codeRef}
                    onClick={(e) => {
                      setCode(
                        e.target.attributes.getNamedItem("data-code").value
                      );
                    }}
                  >
                    {Object.entries(countryCode).map(([key, value], index) => (
                      <li
                        key={key}
                        onClick={() => setIndex(index)}
                        className={
                          "country " +
                          (code == value.dial_code ? "selected-country" : "")
                        }
                        data-code={value.dial_code}
                        tabIndex="0"
                      >
                        {value.name} +{value.dial_code}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <input
                className={
                  "margin-bottom-0 " +
                  (isError.mobileNumber ? "error-input" : "")
                }
                type="text"
                name="mobile-number"
                id="mobile-number"
                placeholder="Enter your mobile number"
                required
                // ref={mobileNumberRef}
                value={mobileNumber}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <span
              className={
                "error " + (isError.mobileNumber ? "error-message" : "")
              }
            >
              {isError.mobileNumber ? "" : "Please enter your mobile number."}
              {errorMessage.mobileNumber}
            </span>
          </div>
        </Form.Body>
        <Form.Footer formClass="padding-top-0 padding-bottom-2">
          <button
            type="submit"
            value="submit"
            className="next-button submit button padding-horizontal-2 margin-bottom-1"
          >
            Submit
          </button>
          {!isMobile && (
            <p>
              Don't have an account yet?{" "}
              <a
                href={`${appUrl}/home/request-trials`}
                rel="noopener noreferrer nofollow"
                className="margin-bottom-1"
              >
                Request a free trial here
              </a>
            </p>
          )}
        </Form.Footer>
      </Form>
    </>
  );
};

export default ForgotEmail;

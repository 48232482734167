import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Form from "../../form/Form";
import AlertMessage from "./alert/AlertMessage";

const ERROR = {
  status: false,
  message: "",
};

const AccountOtpLogin = ({
  authToken,
  flashMsg,
  flashType,
  isBackupCode,
  isMobile = false,
  isOtpMobile = false,
}) => {
  let history = useHistory();
  const { state } = useLocation();
  const [formType, setFormType] = useState("otp");
  const [isFullNameError, setFullNameError] = useState(ERROR);
  const [isEmailError, setEmailError] = useState(ERROR);
  const [isContactDetailsError, setContactDetailsError] = useState(ERROR);
  const [isOtpCodeError, setOtpCodeError] = useState(ERROR);
  const fullNameRef = useRef();
  const emailRef = useRef();
  const contactDetailsRef = useRef();
  const otpCodeRef = useRef();

  useEffect(() => {
    if (isBackupCode) {
      setFormType("backupCode");
    }

    if (flashType == "notice" && isMobile && isOtpMobile) {
      let androidJSIBridge;
      let iosJSIBridge;

      androidJSIBridge = new JSIBridge(new AndroidBridge());
      iosJSIBridge = new JSIBridge(new IosBridge());

      androidJSIBridge.send(
        SquadzipSenderCode.AUTHENTICATED_OTP,
        DataGenerator.generateAuthenticatedOtpData(true)
      );
      iosJSIBridge.send(
        SquadzipSenderCode.AUTHENTICATED_OTP,
        DataGenerator.generateAuthenticatedOtpData(true)
      );
    }
  }, []);

  function switchForm(event, formType) {
    event.preventDefault();

    // reset error hash
    setFullNameError(ERROR);
    setContactDetailsError(ERROR);
    setOtpCodeError(ERROR);

    // update the form type
    setFormType(formType);
  }

  function goBack() {
    if (formType === "otp") {
      history.goBack();
    } else {
      setFormType("otp");
    }
  }

  function handleOnchange(event) {
    if (event.target.name === "user[otp_attempt]" && isOtpCodeError.status) {
      setOtpCodeError(ERROR);
    }

    if (event.target.name === "full_name" && isFullNameError.status) {
      setFullNameError(ERROR);
    }

    if (
      event.target.name === "contact_details" &&
      isContactDetailsError.status
    ) {
      setContactDetailsError(ERROR);
    }

    if (event.target.name === "email" && isEmailError.status) {
      setEmailError(ERROR);
    }
  }

  function validateForm(event) {
    if (formType === "needHelp") {
      const fullName = fullNameRef.current.value;
      const contactDetails = contactDetailsRef.current.value;
      const email = emailRef.current.value;

      if (fullName.trim().length <= 0) {
        setFullNameError({
          status: true,
          message: "Full name is required.",
        });
      }

      if (contactDetails.trim().length <= 0) {
        setContactDetailsError({
          status: true,
          message: "Contact details is required.",
        });
      }

      if (email.trim().length <= 0) {
        setEmailError({
          status: true,
          message: "Email is required.",
        });
      }

      if (
        fullName.trim().length <= 0 ||
        contactDetails.trim().length <= 0 ||
        email.trim().length <= 0
      ) {
        event.preventDefault();
      }
    } else {
      const otpCode = otpCodeRef.current.value;

      if (otpCode.trim().length <= 0) {
        setOtpCodeError({
          status: true,
          message: `${
            formType === "otp" ? "Login" : "Backup"
          } code is required.`,
        });

        event.preventDefault();
      }
    }
  }

  const DisclaimerNote = ({ formType }) => {
    if (formType === "otp") {
      return (
        <div>
          <p className="disclaimer">
            Enter the 6-digit code generated by your authentication app.
          </p>
        </div>
      );
    } else if (formType === "backupCode") {
      return (
        <div>
          <p className="disclaimer">
          Enter one of the 10-digit backup codes provided when you set up two-factor authentication on your account. It may be saved as a screenshot on your phone.
          </p>
          <p className="disclaimer">Each backup code can only be used once.</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="disclaimer">
            We understand that you need to access your account and your
            authentication app or backup codes may not be available to you as of
            the moment.
          </p>
          <p className="disclaimer">
            For immediate access, please contact your{" "}
            <strong>Company Assigned Squadzip Admin</strong> in case you need to
            disable two-factor authentication on your Squadzip Account.
          </p>
          <p className="disclaimer">
            You may also fill up the following fields below in case you need
            further assistance. Our support team will reach out to you after you
            send a request to recover your account.
          </p>
        </div>
      );
    }
  };

  const OtpFooterLinkButton = ({ formType }) => {
    return (
      <Form.Footer formClass="padding-top-0 padding-bottom-2">
        <button className="next-button submit button padding-horizontal-2">
          Continue
        </button>

        {formType === "otp" ? (
          <a
            href="#"
            onClick={(e) => switchForm(e, "backupCode")}
            className="margin-bottom-1"
          >
            Use backup code to login
          </a>
        ) : (
          <a
            href="#"
            onClick={(e) => switchForm(e, "otp")}
            className="margin-bottom-1"
          >
            Use authentication app to login
          </a>
        )}

        <a
          href="#"
          onClick={(e) => switchForm(e, "needHelp")}
          className="margin-bottom-1"
        >
          Get Help
        </a>
      </Form.Footer>
    );
  };

  const GetHelpFooterButton = () => {
    return (
      <Form.Footer formClass="padding-top-0 padding-bottom-2">
        <button className="next-button submit button padding-horizontal-2">
          Send a request
        </button>
      </Form.Footer>
    );
  };

  const OtpInputForm = ({ formType }) => {
    return (
      <div>
        <div className="flex-container align-left align-center-left">
          <label htmlFor="OTP Code">
            {
              {
                otp: "Login Code",
                backupCode: "Backup Code",
              }[formType]
            }
          </label>
        </div>
        <div className="email-container">
          <input type="hidden" name={formType} value="true" />
          <input
            ref={otpCodeRef}
            type="text"
            name="user[otp_attempt]"
            placeholder={
              formType === "otp" ? "Enter login code" : "Enter backup code"
            }
            className={isOtpCodeError.status ? "error-input" : ""}
            id="user_otp_attempt"
            autoComplete="off"
            onChange={(e) => handleOnchange(e)}
          />
          <span
            className={
              "error " +
              (isOtpCodeError.status ? "error-message display" : "hide")
            }
          >
            {isOtpCodeError.message}
          </span>
        </div>
      </div>
    );
  };

  const NeedHelpInputForm = () => {
    return (
      <div>
        <div className="flex-container align-left align-center-left">
          <label htmlFor="Full Name">
            <span style={{ color: "red" }}>*</span> Full Name
          </label>
        </div>
        <div className="email-container">
          <input
            ref={fullNameRef}
            type="text"
            name="full_name"
            placeholder="Enter your full name registered on Squadzip"
            className={isFullNameError.status ? "error-input" : ""}
            onChange={(e) => handleOnchange(e)}
          />
          <span
            className={
              "error " +
              (isFullNameError.status ? "error-message display" : "hide")
            }
          >
            {isFullNameError.message}
          </span>
        </div>

        <div className="flex-container align-left align-center-left">
          <label htmlFor="Full Name">
            <span style={{ color: "red" }}>*</span> Email Address
          </label>
        </div>
        <div className="email-container">
          <input
            ref={emailRef}
            type="email"
            name="email"
            placeholder="Enter your corporate Email Address (e.g. juandelacruz@delacruzcorporation.com)"
            className={isEmailError.status ? "error-input" : ""}
            onChange={(e) => handleOnchange(e)}
          />
          <span
            className={
              "error " +
              (isEmailError.status ? "error-message display" : "hide")
            }
          >
            {isEmailError.message}
          </span>
        </div>

        <div className="flex-container align-left align-center-left">
          <label htmlFor="Full Name">
            <span style={{ color: "red" }}>*</span> Mobile Number
          </label>
        </div>
        <div className="email-container">
          <input
            ref={contactDetailsRef}
            type="text"
            name="contact_details"
            placeholder="e.g. 09XXXXXXXXX or +639XXXXXXXXX"
            className={isContactDetailsError.status ? "error-input" : ""}
            onChange={(e) => handleOnchange(e)}
          />
          <span
            className={
              "error " +
              (isContactDetailsError.status ? "error-message display" : "hide")
            }
          >
            {isContactDetailsError.message}
          </span>
        </div>
      </div>
    );
  };

  const BackLink = ({ isMobile, formType }) => {
    if (!isMobile) {
      return (
        <span onClick={() => goBack()} className="back-btn">
          <i className="fas fa-arrow-left"></i> Back
        </span>
      );
    }

    if (isMobile && formType != "otp") {
      return (
        <span onClick={() => goBack()} className="back-btn">
          <i className="fas fa-arrow-left"></i> Back
        </span>
      );
    }

    return null;
  };

  return (
    <Form
      formClass="margin-auto-form"
      actionMethod="post"
      action={
        formType === "needHelp" ? "/v2/two_factor_settings/otp_need_help" : ""
      }
      onSubmit={(e) => validateForm(e)}
    >
      <Form.Section formClass="flex-container align-left align-center-left padding-left-2 padding-top-1 padding-bottom-0">
        <BackLink isMobile={isMobile} formType={formType} />
      </Form.Section>

      <Form.Heading formClass="padding-top-2 padding-bottom-0">
        <div className="form-heading">
          <h3 className="margin-bottom-1">
            {
              {
                otp: "Two-Factor Authentication Required",
                backupCode: "Enter a backup code to access your account",
                needHelp: "Get Help",
              }[formType]
            }
          </h3>
        </div>
        {(flashMsg || state?.message) && (
          <div className="hidden width-75 margin-auto">
            <AlertMessage type={flashType || state?.type}>
              {flashMsg || state?.message}
            </AlertMessage>
          </div>
        )}
      </Form.Heading>

      <Form.Body formClass="padding-top-0 padding-bottom-1">
        <DisclaimerNote formType={formType} />
        <div>
          <input name="utf8" type="hidden" value="✓" />
          <input name="authenticity_token" type="hidden" value={authToken} />
        </div>
        {formType === "needHelp" ? (
          <NeedHelpInputForm formType={formType} />
        ) : (
          <OtpInputForm formType={formType} />
        )}
      </Form.Body>
      {formType === "needHelp" ? (
        <GetHelpFooterButton />
      ) : (
        <OtpFooterLinkButton formType={formType} />
      )}
    </Form>
  );
};

export default AccountOtpLogin;

import React, { useState } from 'react';
import SquadzipLogo from 'images/logo/squadzip_logo_black_100px_33px.png';
import { NavLink, useLocation, Link } from 'react-router-dom';

const TopNavBar = ({ appUrl }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const { pathname } = useLocation();
  return (
    <header id='header-login'>
      <nav className={isChecked ? 'open-navbar' : ''}>
        <div className='top-bar'>
          <div className='top-bar-left'>
            <a
              className='navbar-brand'
              rel='noopener noreferrer nofollow'
              data-turbolinks='false'
              href={`${appUrl}/home`}>
              <img
                src={SquadzipLogo}
                alt='Squadzip Logo'
                height='35px'
                width='100px'
                id='sz-logo-img'
              />
            </a>
            <input
              onChange={(e) => handleChange(e)}
              type='checkbox'
              id='hamburger-toggle'
              readOnly
            />
            <label className='hamburger-icon' htmlFor='hamburger-toggle'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                fill='currentColor'
                className='bi bi-list'
                viewBox='0 0 16 16'>
                <path
                  fillRule='evenodd'
                  d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
                />
              </svg>
            </label>
          </div>
          <div className={'top-bar-right ' + (isChecked ? 'open-navbar' : '')}>
            <ul>
              <li>
                <a
                  href={`${appUrl}/home`}
                  data-turbolinks='false'
                  rel='noopener noreferrer nofollow'>
                  Home
                </a>
              </li>
              <li
                className={
                  window.location.pathname === '/v2/users/sign_in' ||
                  window.location.pathname === '/v2/account/index' ||
                  window.location.pathname === '/v2/account/login-zoom' ||
                  window.location.pathname === '/v2/account/forgot-email' ||
                  window.location.pathname === '/v2/account/forgot-password' ||
                  window.location.pathname === '/v2/account/reset-password' ||
                  window.location.pathname === '/v2/users/password/edit' ||
                  window.location.pathname === '/v2/users/password/new' ||
                  window.location.pathname === '/v2/account/forgot-subdomain'
                    ? 'li-active'
                    : ''
                }>
                <NavLink
                  to={
                    window.location.pathname === '/v2/account/index' ||
                    window.location.pathname ===
                      '/v2/account/forgot-subdomain' ||
                    window.location.pathname === '/v2/account/login-zoom'
                      ? '/v2/account/index'
                      : '/v2/users/sign_in'
                  }
                  data-turbolinks='false'
                  rel='noopener noreferrer nofollow'
                  isActive={() =>
                    [
                      '/v2/account/index',
                      '/v2/account/login',
                      '/v2/account/login-zoom',
                      '/v2/account/forgot-email',
                      '/v2/account/forgot-subdomain',
                      '/v2/account/forgot-password',
                      '/v2/account/reset-password',
                      '/v2/users/password/edit',
                      '/v2/users/password/new',
                      '/v2/users/sign_in',
                    ].includes(pathname)
                  }>
                  Sign in
                </NavLink>
              </li>
              <li>
                <a
                  href={`${appUrl}/home/contact-us-v2`}
                  data-turbolinks='false'
                  rel='noopener noreferrer nofollow'>
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href={`${appUrl}/home/request-trials`}
                  data-turbolinks='false'
                  rel='noopener noreferrer nofollow'
                  className='request-button'>
                  Request a Trial
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default TopNavBar;

import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoadingCircle from '../shared/LoadingCircle';
import Footer from './sections/Footer';
import TopNavBar from './sections/TopNavBar';

const Subdomain = lazy(() => import('./pages/subdomain/Subdomain'));
const ZoomLogin = lazy(() => import('./pages/subdomain/ZoomLogin'));
const ForgotEmail = lazy(() => import('./pages/forgot_account/ForgotEmail'));
const ForgotPassword = lazy(() => import('./pages/forgot_account/ForgotPassword'));
const ForgotCompany = lazy(() => import('./pages/forgot_account/ForgotCompany'));
const AccountLogin = lazy(() => import('./pages/account_login/AccountLogin'));
const AccountOtpLogin = lazy(() => import('./pages/account_login/AccountOtpLogin'));
const ResetPassword = lazy(() => import('./pages/forgot_account/ResetPassword'));

const SubdomainLogin = lazy(() => import('./pages/tenant_switch/SubdomainLogin'));

const LoadingScreen = () => {
  return (
    <div className='sz-lazy-loading-screen'>
      <LoadingCircle />
    </div>
  );
};

const LoginPage = ({ authToken, flashMsg, flashType, isOtpLogin, isBackupCode }) => {
  if (isOtpLogin) {
    return (
      <AccountOtpLogin
        authToken={authToken}
        flashMsg={flashMsg}
        flashType={flashType}
        isBackupCode={isBackupCode}
      />
    );
  } else {
    return <AccountLogin authToken={authToken} flashMsg={flashMsg} flashType={flashType} />;
  }
};

const MainLogin = ({
  authToken,
  isMobile,
  appUrl,
  domain,
  flashMsg = '',
  flashType = '',
  isOtpLogin = false,
  isBackupCode = false,
  isOtpMobile = false,
}) => {
  return (
    <>
      <Router>
        {!isMobile && <TopNavBar appUrl={appUrl} />}
        <Suspense fallback={<LoadingScreen />}>
          <section className={'form-container ' + (isMobile ? 'padding-0 full-height' : '')}>
            <Switch>
              <Route
                path='/v2/users/sign_in'
                render={() => (
                  <LoginPage
                    authToken={authToken}
                    flashMsg={flashMsg}
                    flashType={flashType}
                    isOtpLogin={isOtpLogin}
                    isBackupCode={isBackupCode}
                  />
                )}
              />
              <Route
                path='/v2/two_factor_settings/mobile_otp'
                render={() => (
                  <AccountOtpLogin
                    authToken={authToken}
                    flashMsg={flashMsg}
                    flashType={flashType}
                    isMobile={isMobile}
                    isOtpMobile={isOtpMobile}
                  />
                )}
              />
              <Route
                path='/v2/account/index'
                render={() => <Subdomain appUrl={appUrl} domain={domain} />}
              />
              <Route
                path='/v2/account/forgot-subdomain'
                render={() => <ForgotCompany appUrl={appUrl} isMobile={isMobile} />}
              />
              <Route path='/v2/account/login' render={() => <AccountLogin />} />
              <Route path='/v2/account/login-zoom' render={() => <ZoomLogin appUrl={appUrl} />} />
              <Route
                path='/v2/account/forgot-email'
                render={() => <ForgotEmail isMobile={isMobile} appUrl={appUrl} />}
              />
              <Route
                path='/v2/users/password/new'
                render={() => <ForgotPassword appUrl={appUrl} isMobile={isMobile} />}
              />
              <Route
                path='/v2/users/password/edit'
                render={() => <ResetPassword authToken={authToken} isMobile={isMobile} />}
              />
              {/* route for tenant switch subdomain login */}
              <Route
                path='/v2/account_user_collections/new'
                render={() => (<SubdomainLogin authToken={authToken} isMobile={isMobile} domain={domain} />)}
              />
            </Switch>
          </section>
        </Suspense>
        {!isMobile && <Footer appUrl={appUrl} />}
      </Router>
    </>
  );
};

export default MainLogin;

import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Form from "../../form/Form";
import AlertMessage from "../account_login/alert/AlertMessage";

const ForgotPassword = ({ appUrl, isMobile }) => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("user[email]", email);
    let url = "/v2/users/password";
    fetch(url, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token":
          document.getElementsByTagName("META")["csrf-token"].content,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "success") {
          // console.log('success', data);
          setSuccess(true);
        } else {
          // console.log('fail', data);
          setError(true);
          setErrorMessage(data.message);
          setEmail("");
          setSuccess(false);
        }
      })
      .catch((data) => console.log(data));
  };

  const handleOnchange = (e) => {
    isError && setError(false);
    setEmail(e.target.value);
  };

  return (
    <>
      {isSuccess && !isMobile && (
        <Redirect
          push
          to={{
            pathname: "/v2/users/sign_in",
            state: {
              message:
                "Your request to change the password is being processed by our system. You will receive an email once our system detects that you have an account with us.",
              type: "success",
            },
          }}
        />
      )}
      <Form onSubmit={(e) => handleSubmit(e)}>
        {isMobile ? null : (
          <Form.Section formClass="flex-container align-left align-center-left padding-left-2 padding-top-1 padding-bottom-0">
            <span
              onClick={() =>
                history.replace({ pathname: "/v2/users/sign_in", state: {} })
              }
              className="back-btn"
            >
              <i className="fas fa-arrow-left"></i> Back
            </span>
          </Form.Section>
        )}

        <Form.Heading formClass="padding-top-1 padding-bottom-0">
          <div className="padding-horizontal-2 form-heading">
            <h3 className="margin-bottom-1">Forgot your password?</h3>
            <h4 className="text-primary">
              Don't worry, we'll send you a link so you can reset your password.
              Let us first verify who you are.
            </h4>
          </div>
        </Form.Heading>
        <Form.Body formClass="padding-bottom-0">
          {isSuccess && isMobile && (
            <AlertMessage type="notice">
              You will receive an email with instructions on how to reset your
              password in a few minutes.
            </AlertMessage>
          )}
          <div>
            <div className="flex-container align-left align-center-left">
              <label htmlFor="email">Email address</label>
            </div>
            <input
              autoComplete="on"
              className={isError ? "error-input" : ""}
              type="text"
              name="email"
              id="email"
              placeholder="Enter your email address"
              required
              value={email}
              onChange={(e) => handleOnchange(e)}
            />
            <span
              htmlFor="email"
              className={"error " + (isError ? "error-message" : "")}
            >
              {isError ? errorMessage : "Error"}
            </span>
          </div>
        </Form.Body>

        <Form.Footer formClass="padding-bottom-2 padding-top-0">
          <button
            type="submit"
            value="submit"
            className="next-button submit button padding-horizontal-2 margin-bottom-2"
          >
            Submit
          </button>

          {!isMobile && (
            <p className="margin-top-1">
              Don't have an account yet?{" "}
              <a
                href={`${appUrl}/home/request-trials`}
                rel="noopener noreferrer nofollow"
              >
                Request a free trial here
              </a>
            </p>
          )}
        </Form.Footer>
      </Form>
    </>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';

const Message = ({ children, type, ms = 10000 }) => {
  const [show, setShow] = useState(true);

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setShow(false);
    }, ms);

    return () => {
      clearTimeout(timeId);
      // setShow(true)
    };
  });

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <div
      className={
        'success-request-message margin-bottom-1 ' +
        (show ? 'show ' : 'hidden ') +
        type
      }>
      <div className='flex-container flex-dir-row align-justify'>
        <div className='flex-container padding-left-1 padding-right-2'>
          <div className='margin-auto padding-right-1 icon-div'>
            <span className='margin-auto flex-container align-middle-center success-icon'>
              {type == 'success' || type == 'notice' ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'>
                  <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z' />
                </svg>
              ) : (
                <svg
                  className='error-icon'
                  height='20'
                  // style='overflow:visible;enable-background:new 0 0 32 32'
                  viewBox='0 0 32 32'
                  width='20'>
                  <g>
                    <g id='Error_1_'>
                      <g id='Error'>
                        <circle
                          cx='16'
                          cy='16'
                          id='BG'
                          r='16'
                          // style='fill:#D72828;'
                        />
                        <path
                          d='M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z'
                          id='Exclamatory_x5F_Sign'
                          // style='fill:#E6E6E6;'
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </span>
          </div>
          <div className='margin-auto padding-left-1'>{children}</div>
        </div>
        <div className='padding-right-1 margin-auto margin-left-0 margin-right-0'>
          <button onClick={() => setShow(false)} type='button'>
            <i className='fas fa-times'></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Message;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Form from "../../form/Form";

const PASSWORD_VALIDATION = {
  character: false,
  numbers: false,
  symbols: false,
};

const hasNumber = (str) => {
  return /^.*[0-9].*$/.test(str);
};

const hasSymbol = (str) => {
  return /^.*([-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+).*$/.test(str);
};

const isValid = (str) => {
  return /^[A-Za-z]*(?=.*[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|])(?=.*[0-9]).{8,}$/.test(
    str
  );
};

const ResetPassword = ({ authToken }) => {
  const [passwordCondition, setPasswordCondition] =
    useState(PASSWORD_VALIDATION);
  const [passwordType, setPasswordType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [disableButton, setDisable] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [forceLogout, setForceLogout] = useState(true);

  const search = useLocation().search;
  const getSearchParams = (param) => {
    return new URLSearchParams(search).get(param);
  };
  const token = getSearchParams("reset_password_token");

  //regex ^[A-Za-z]*(?=.*[!@#$&*])(?=.*[0-9]).{8,}$
  const validatePassword = () => {
    //validate if password is at least 8 characters
    /^.{8,}$/.test(newPassword)
      ? setPasswordCondition((prev) => ({
          ...prev,
          character: true,
        }))
      : setPasswordCondition((prev) => ({
          ...prev,
          character: false,
        }));

    //validate if password contains at least 1 number
    hasNumber(newPassword)
      ? setPasswordCondition((prev) => ({
          ...prev,
          numbers: true,
        }))
      : setPasswordCondition((prev) => ({
          ...prev,
          numbers: false,
        }));

    //validate if password contains at least 1 special character/symbol
    hasSymbol(newPassword)
      ? setPasswordCondition((prev) => ({
          ...prev,
          symbols: true,
        }))
      : setPasswordCondition((prev) => ({
          ...prev,
          symbols: false,
        }));

    //validate if whole password is valid to enable button
    isValid(newPassword) ? setDisable(false) : setDisable(true);
  };

  const toggleShowPassword = (e) => {
    e.currentTarget.id === "show-new-pass"
      ? setPasswordType(!passwordType)
      : setConfirmPasswordType(!confirmPasswordType);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("authenticity_token", authToken);
    form.append("user[reset_password_token]", token);
    form.append("user[password]", newPassword);
    form.append("user[password_confirmation]", newPasswordConfirmation);
    form.append("user[force_logout]", forceLogout);

    let url = "/v2/users/password";
    fetch(url, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token":
          document.getElementsByTagName("META")["csrf-token"].content,
      },
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "success") {
          window.location.href = "/v2/feed";
        } else {
          setError(true);
          setDisable(true);
          setErrorMessage(data.error.description);
          setNewPassword("");
          setPasswordType(true);
          setConfirmPasswordType(true);
          setNewPasswordConfirmation("");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    validatePassword();
  }, [newPassword]);

  const handleOnchange = (e) => {
    isError && setError(false);
    setNewPassword(e.target.value);
  };

  return (
    <Form formClass="margin-auto-form" onSubmit={(e) => handleSubmit(e)}>
      <Form.Heading formClass="padding-top-2 padding-bottom-0">
        <div className="padding-horizontal-2 form-heading">
          <h3 className="margin-bottom-0">Reset your password</h3>
          <h5 className="margin-bottom-1">
            Your new password must contain the following:
          </h5>
          <div className="flex-container">
            <ul className="flex-container align-center-middle align-center align-middle flex-dir-column">
              <li className="flex-container align-center-middle align-center align-middle">
                <span
                  className={
                    "margin-right-1 flex-container align-middle-center " +
                    (passwordCondition.character ? "accept" : "")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" />
                  </svg>
                </span>
                8 characters (minimum)
              </li>
              <li className="flex-container align-center-middle align-center align-middle">
                <span
                  className={
                    "margin-right-1 flex-container align-middle-center " +
                    (passwordCondition.numbers ? "accept" : "")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" />
                  </svg>
                </span>
                Numbers (at least 1)
              </li>
              <li className="flex-container align-center-middle align-center align-middle">
                <span
                  className={
                    "margin-right-1 flex-container align-middle-center " +
                    (passwordCondition.symbols ? "accept" : "")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" />
                  </svg>
                </span>
                Symbols (at least 1)
              </li>
            </ul>
          </div>
        </div>
      </Form.Heading>
      <Form.Body formClass="padding-bottom-1 padding-top-1">
        <div>
          <div className="flex-container align-left align-center-left">
            <label htmlFor="new-password">New password</label>
          </div>
          <div
            className={"password-container " + (isError ? "error-input" : "")}
          >
            <input
              type={passwordType ? "password" : "text"}
              id="new-password"
              name="new-password"
              className={"margin-0 " + (isError ? "error-input" : "")}
              placeholder="Enter your new password"
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={newPassword}
              onChange={(e) => handleOnchange(e)}
            />
            {!passwordType ? (
              <i
                id="show-new-pass"
                onClick={(e) => toggleShowPassword(e)}
                className="far fa-eye"
              ></i>
            ) : (
              <i
                id="show-new-pass"
                onClick={(e) => toggleShowPassword(e)}
                className="far fa-eye-slash"
              ></i>
            )}
          </div>
          <span
            className={
              "error margin-bottom-1 " + (isError ? "error-message" : "")
            }
          >
            {isError ? errorMessage : "error"}
          </span>
        </div>
        <div>
          <div className="flex-container align-left align-center-left">
            <label htmlFor="confirm-password">Confirm Password</label>
          </div>
          <div
            className={"password-container " + (isError ? "error-input" : "")}
          >
            <input
              type={confirmPasswordType ? "password" : "text"}
              id="confirm-password"
              name="confirm-password"
              className={"margin-0 " + (isError ? "error-input" : "")}
              placeholder="Confirm your new password"
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={newPasswordConfirmation}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
            />
            {!confirmPasswordType ? (
              <i
                id="show-cpass"
                onClick={(e) => toggleShowPassword(e)}
                className="far fa-eye"
              ></i>
            ) : (
              <i
                id="show-cpass"
                onClick={(e) => toggleShowPassword(e)}
                className="far fa-eye-slash"
              ></i>
            )}
          </div>
          <div className="flex-container align-justify">
            <div className="flex-container align-middle-center">
              <input
                type="checkbox"
                name="logout-devices"
                id="logout-devices"
                defaultChecked={true}
                onClick={(e) =>
                  forceLogout ? setForceLogout(false) : setForceLogout(true)
                }
                value={forceLogout}
                className="margin-0"
              />
              <label htmlFor="logout-devices">
                Require all my devices to log in again with my new password
              </label>
            </div>
          </div>
          <span className={"error " + (isError ? "error-message" : "")}>
            {isError ? errorMessage : "error"}
          </span>
        </div>
      </Form.Body>
      <Form.Footer formClass="padding-vertical-0 padding-bottom-2">
        <button
          disabled={disableButton ? true : false}
          className="next-button submit button padding-horizontal-2"
        >
          Submit
        </button>
        <Link
          to="/v2/users/sign_in"
          rel="noopener noreferrer nofollow"
          className="link-hover"
        >
          Sign in
        </Link>
      </Form.Footer>
    </Form>
  );
};

export default ResetPassword;

import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Form from "../../form/Form";
import AlertMessage from "./alert/AlertMessage";

const EMAIL_ERROR = {
  status: false,
  message: "",
};

const PASSWORD_ERROR = {
  status: false,
  message: "",
};

const AccountLogin = ({ authToken, flashMsg, flashType }) => {
  const { state } = useLocation();
  const [passwordType, setPasswordType] = useState(true);
  const [isEmailError, setEmailError] = useState(EMAIL_ERROR);
  const [isPasswordError, setPasswordError] = useState(PASSWORD_ERROR);
  const tenant = window.location.href.split(".")[0].split("/")[2];
  const emailRef = useRef();
  const passwordRef = useRef();
  console.log("state", flashMsg);
  const toggleShowPassword = () => {
    setPasswordType(!passwordType);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function validateForm(event) {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    let isError = false;

    if (!email && !password) {
      setEmailError({
        status: true,
        message: "Email is required",
      });

      setPasswordError({
        status: true,
        message: "Password is required",
      });
      isError = true;
    }

    if (!email && password) {
      setEmailError({
        status: true,
        message: "Email is required",
      });
      setPasswordError(PASSWORD_ERROR);
      isError = true;
    }

    if (email && !password) {
      setPasswordError({
        status: true,
        message: "Password is required",
      });
      setEmailError(EMAIL_ERROR);
      isError = true;
    }

    if (email && !isValidEmail(email)) {
      setEmailError({
        status: true,
        message: "Please enter a valid email address",
      });
    }

    if (isError) {
      event.preventDefault();
    }
  }

  function popupCenter(event, name) {
    event.preventDefault();

    let leftPosition,
      topPosition,
      width,
      height,
      url,
      hostname,
      hostWithoutSubDomain,
      subodmain;
    width = 720;
    height = 608;
    leftPosition = screen.width / 2 - width / 2;
    topPosition = screen.height / 2 - height / 2;
    hostname = window.location.host.split(".");
    subodmain = hostname.shift();
    hostWithoutSubDomain = hostname.join(".");
    url = `https://${hostWithoutSubDomain}/v2/authsession/facebook?company=${subodmain}&display=popup`;

    return window.open(
      url,
      name,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        topPosition +
        ", left=" +
        leftPosition
    );
  }

  const handleOnchange = (e) => {
    if (e.target.type === "email" && isEmailError.status) {
      setEmailError({ status: false, message: "" });
    }

    if (e.target.type === "password" && isPasswordError.status) {
      setPasswordError({ status: false, message: "" });
    }
  };

  return (
    <Form
      formClass="margin-auto-form"
      actionMethod="post"
      onSubmit={(e) => validateForm(e)}
    >
      <Form.Heading formClass="padding-top-2 padding-bottom-0">
        <div className="form-heading">
          <h3 className="margin-bottom-1">
            You are on subdomain:{" "}
            <span className="text-primary">{tenant.length > 0 && tenant}</span>
          </h3>
        </div>
        {(flashMsg || state?.message) && (
          <div className="hidden width-75 margin-auto alert-message">
            <AlertMessage type={flashType || state?.type}>
              {flashMsg || state?.message}
            </AlertMessage>
          </div>
        )}
      </Form.Heading>
      <Form.Body formClass="padding-top-0 padding-bottom-1">
        <div>
          <input name="utf8" type="hidden" value="✓" />
          <input name="authenticity_token" type="hidden" value={authToken} />
        </div>
        <div>
          <div className="flex-container align-left align-center-left">
            <label htmlFor="email">Email Address</label>
          </div>
          <div className="email-container">
            <input
              ref={emailRef}
              tabIndex="1"
              autoFocus="autofocus"
              className={isEmailError.status ? "error-input" : ""}
              autoComplete="on"
              type="email"
              name="user[email]"
              placeholder="Enter your email address"
              id="email"
              onChange={(e) => handleOnchange(e)}
            />
            <span
              className={
                "error " +
                (isEmailError.status ? "error-message display" : "hide")
              }
            >
              {isEmailError.message}
            </span>
            <div className="flex-container align-right align-center-right">
              <a
                tabIndex="3"
                href="/v2/account/forgot-email"
                rel="noopener noreferrer nofollow"
              >
                I forgot my email address
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="flex-container align-left align-center-left">
            <label htmlFor="password">Password</label>
          </div>
          <div className="password-container-wrapper">
            <div className="password-container">
              <input
                ref={passwordRef}
                tabIndex="2"
                type={passwordType ? "password" : "text"}
                className={isPasswordError.status ? "error-input" : ""}
                name="user[password]"
                autoComplete="on"
                placeholder="Enter your password"
                id="password"
                onChange={(e) => handleOnchange(e)}
              />

              {!passwordType ? (
                <i
                  onClick={() => toggleShowPassword()}
                  className="far fa-eye"
                ></i>
              ) : (
                <i
                  onClick={() => toggleShowPassword()}
                  className="far fa-eye-slash"
                ></i>
              )}
            </div>
            <span
              className={
                "error padding-bottom-1 " +
                (isPasswordError.status ? "error-message display" : "hide")
              }
            >
              {isPasswordError.message}
            </span>
          </div>
          <div className="flex-container align-justify">
            <div className="flex-container align-middle-center">
              <input
                tabIndex="5"
                type="checkbox"
                name="remember-me"
                id="remember-me"
                className="margin-0"
              />
              <label htmlFor="remember-me">Remember me</label>
            </div>

            <a
              tabIndex="4"
              href="/v2/users/password/new"
              rel="noopener noreferrer nofollow"
            >
              I forgot my password
            </a>
          </div>
        </div>
      </Form.Body>
      <Form.Footer formClass="padding-top-0 padding-bottom-2">
        <button
          tabIndex="6"
          className="next-button submit button padding-horizontal-2"
        >
          Log in
        </button>
        <a
          tabIndex="7"
          onClick={(e) => popupCenter(e, "authPopup")}
          href="#"
          className="facebook-button submit button"
        >
          Login with Facebook
        </a>
      </Form.Footer>
      <Form.Section formClass="flex-container align-left align-center-left padding-horizontal-2 padding-vertical-0 padding-bottom-2 disclaimer-section">
        <p className="disclaimer text-muted width-75 margin-auto">
          Disclaimer:{" "}
          <em>
            Access to Squadzip via the Facebook login feature will not work if
            the wifi or data connection you are using does not allow access to
            facebook.com.
          </em>
        </p>
      </Form.Section>
    </Form>
  );
};

export default AccountLogin;

import React, { useState } from 'react';
import Form from '../../form/Form';
import FormLinks from './util/FormLinks';

const ZoomLogin = ({ appUrl }) => {
  const [subdomain, setSubdomain] = useState('');
  const [isError, setError] = useState(false);

  const handleOnchange = (e) => {
    isError && setError(false);
    setSubdomain(e.target.value);
  };

  return (
    <Form
      formClass='margin-auto-form'
      action='/zoom/authorization?locale=en'
      method='GET'>
      <Form.Heading formClass='padding-top-2'>
        <div className='padding-horizontal-2 form-heading'>
          <h3 className='margin-bottom-1 padding-horizontal-5'>
            Login to Squadzip to link your Zoom Account
          </h3>
          <h4 className='text-primary'>
            Enter your company's subdomain and click <strong>Next</strong> to
            continue:
          </h4>
        </div>
      </Form.Heading>
      <Form.Body formClass='padding-bottom-1'>
        <div
          className={
            'subdomain-input-container ' + (isError ? 'error-input' : '')
          }>
          <input
            className='subdomain-input'
            type='text'
            name='subdomain'
            id='subdomain'
            value={subdomain}
            onChange={(e) => handleOnchange(e)}
            placeholder='subdomain'
            required
          />
          <div className='squadzip-com'>
            <span>.squadzip.com</span>
          </div>
        </div>
      </Form.Body>
      <Form.Footer formClass='padding-bottom-2'>
        <button className='next-button submit button padding-horizontal-2 margin-bottom-1'>
          Next &#8250;
        </button>
        <FormLinks appUrl={appUrl} />
      </Form.Footer>
    </Form>
  );
};

export default ZoomLogin;

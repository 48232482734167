import Logo from "images/logo/Squadzip_Main_Logo.png";
import React, { useEffect } from "react";

const FacebookLandingPage = ({ payload, showSyncingTutorial }) => {
  useEffect(() => {
    if (payload["callback_type"] == "login") {
      setTimeout("window.close()", 3000);
      setTimeout("window.opener.location.reload()", 3000);
    }
  }, []);

  const screenResizing = () => {
    if (window.innerWidth >= 720) window.innerWidth = 720;
  };

  useEffect(() => {
    screenResizing();

    window.addEventListener("resize", screenResizing);

    return () => {
      window.removeEventListener("resize", screenResizing);
    };
  }, [screenResizing()]);

  function closeWindow(event, reloadPage = false) {
    event.preventDefault();

    if (reloadPage) {
      window.opener.location.reload();
    }

    window.close();
  }

  function displaySyncingTutorial(event) {
    event.preventDefault();

    showSyncingTutorial();

    // window.close();
  }

  const CloseButton = ({ payload }) => {
    let reloadPage = true;

    if (payload["success"] || payload["callback_type"] == "same_synced") {
      reloadPage = true;
    } else {
      reloadPage = false;
    }

    return (
      <button
        className="button tiny close"
        onClick={(e) => closeWindow(e, reloadPage)}
      >
        Close
      </button>
    );
  };

  const SuccessContent = ({ payload }) => {
    return (
      <div
        className={"auth-cont success-auth-cont " + payload["callback_type"]}
      >
        {payload["callback_type"] == "login" ? (
          <div>
            <h3>Continuing with this account:</h3>
            <h3>{payload["full_name"]}</h3>
          </div>
        ) : (
          <div>
            <h3>{payload["msg"]}</h3>
            <CloseButton payload={payload} />
          </div>
        )}
      </div>
    );
  };

  const ConnectButton = ({ payload }) => {
    return payload["callback_type"] == "login_error" ? (
      <button
        id="sync-tutorial-btn"
        className="button tiny"
        onClick={(e) => displaySyncingTutorial(e)}
      >
        How to Connect
      </button>
    ) : null;
  };

  const FailedContent = ({ payload }) => {
    return (
      <>
        <div
          className={"auth-cont error-auth-cont " + payload["callback_type"]}
        >
          {payload["callback_type"] == "login_resigned" ? (
            <h5>
              Please contact your Squadzip Admin or email us at{" "}
              <span className="sz-support-email">support@squadzip.com</span> for
              account activation.
            </h5>
          ) : (
            <div>
              <h3>{payload["msg"]}</h3>
              <ConnectButton payload={payload} />
            </div>
          )}
        </div>
        <CloseButton payload={payload} />
      </>
    );
  };

  return (
    <div className="omniauth-fb-callback-cont">
      <div className="logo">
        <img
          src={Logo}
          alt="Squadzip Logo"
          height="85"
          width="200"
          id="sz-logo-img"
        />
      </div>

      {payload["success"] ? (
        <SuccessContent payload={payload} />
      ) : (
        <FailedContent payload={payload} />
      )}
    </div>
  );
};

export default FacebookLandingPage;

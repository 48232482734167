import React from "react";

const Form = ({
  children,
  formClass,
  onSubmit,
  isMobile,
  action,
  actionMethod = "get",
}) => {
  return (
    <div className={"card " + (isMobile ? "margin-0 margin-top-1" : "")}>
      <form
        className={"width-100 " + (formClass || "")}
        data-abide
        noValidate
        onSubmit={(e) => onSubmit(e)}
        action={action}
        method={actionMethod}
      >
        {children}
      </form>
    </div>
  );
};

Form.Section = ({ children, formClass }) => {
  return <div className={"card-section " + (formClass || "")}>{children}</div>;
};

Form.Heading = ({ children, formClass }) => {
  return (
    <div
      className={
        "card-section flex-container align-center align-middle align-center-middle flex-dir-column " +
        (formClass || "")
      }
    >
      {children}
    </div>
  );
};

Form.Body = ({ children, formClass }) => {
  return (
    <div
      className={
        "card-section flex-container align-center align-middle align-center-middle " +
        (formClass || "")
      }
    >
      <div className="width-75 input-subdomain">{children}</div>
    </div>
  );
};

Form.Footer = ({ children, formClass }) => {
  return (
    <div
      className={
        "card-section flex-container align-center align-middle align-center-middle flex-dir-column " +
        (formClass || "")
      }
    >
      {children}
    </div>
  );
};

export default Form;
